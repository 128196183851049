import React from "react";
import TextPageLayout from "../../components/layouts/TextPageLayout";
import SEO from "../../components/SEO";
import IsometricHoldsWorkoutGenerator from "../../components/workoutGenerators/IsometricHoldsWorkoutGenerator";

function ImproveYourIsometricHolds() {
  return (
    <TextPageLayout title="Improve Your Isometric Holds">
      <SEO
        path="/workouts/improve-your-isometric-hols/"
        title="Improve Your Isometric Holds and break your fitness plateaus"
        description="Break your plateaus and improve the hold times for your favourite isometric exercises."
      />
      <p>
        Improve Your Isometric Holds is designed to help you break your plateaus
        and improve the hold times for your favourite isometric exercises.
      </p>
      <IsometricHoldsWorkoutGenerator />
      <p>
        To create an Improve Your Isometric Holds workout, you'll need to select
        an isometric exercise that you've already marked as completed in your
        skill tree. We will then create a workout that takes your current max
        hold time into consideration.
      </p>
      <p>
        The workout format is based on Prilepin tables, a workout structure that
        focuses less on long hold times across fewer sets, and instead on
        shorter hold times across many sets.{" "}
        <a
          href="https://stevenlow.org/prilepin-tables-for-bodyweight-strength-isometric-and-eccentric-exercises/"
          target="_blank"
          rel="noreferrer noopener"
        >
          This article
        </a>
        , written by the author of Overcoming Gravity, explains clearly the
        science behind the Prilepin Table.
      </p>
    </TextPageLayout>
  );
}

export default ImproveYourIsometricHolds;
