function getExercisesProgress(userId: string, accessToken: string) {
  return fetch(
    `${process.env.GATSBY_SERVER_URL}/progress/${userId}/exercises`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
}

export default getExercisesProgress;
