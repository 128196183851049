import React, { useState } from "react";
import WorkoutGenerator from "./WorkoutGenerator";
import { WORKOUT_FORMAT } from "../../models/workouts";
import IsometricExerciseSelect from "../IsometricExerciseSelect";
import useAuth from "../../hooks/useAuth";
import Loader from "../Loader";
import "../../styles/IsometricHoldsWorkoutGenerator.css";

function IsometricHoldsWorkoutGenerator() {
  const { tokenRenewalComplete, getAuthenticationState } = useAuth();

  const [selectedExercise, selectExercise] = useState("plank");

  if (!tokenRenewalComplete) return <Loader />;

  return (
    <>
      {getAuthenticationState() && (
        <IsometricExerciseSelect selectExercise={selectExercise} />
      )}
      <WorkoutGenerator
        workoutFormat={WORKOUT_FORMAT.improveYourIsometricHolds}
        exerciseId={selectedExercise}
      />
    </>
  );
}

export default IsometricHoldsWorkoutGenerator;
