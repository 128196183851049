import React from "react";
import Select from "react-select";
import exercises from "../data/exercises/exercises";
import {
  SelectOption,
  defaultContainerStyles,
  getDefaultOptionStyles,
  defaultMenuStyles
} from "./forms/SelectInput";
import { ValueType } from "react-select";
import { OptionProps } from "react-select/src/types";

const customStyles = {
  container: () => ({
    ...defaultContainerStyles
  }),
  option: (_: unknown, state: OptionProps) => ({
    ...getDefaultOptionStyles(state.isFocused)
  }),
  menu: () => ({
    ...defaultMenuStyles
  })
};

const defaultOptions = Object.values(exercises).map(
  ({ title, exerciseId }) => ({
    value: exerciseId,
    label: title
  })
);

interface Props {
  handleChange: (selectedValue: ValueType<SelectOption>) => void;
  defaultValue: {
    label: string;
    value: string;
  };
  options?: {
    label: string;
    value: string;
  }[];
}

function ExerciseSelect(props: Props) {
  const { handleChange, defaultValue, options } = props;

  return (
    <div className="FilterInput__container">
      <Select
        className="FilterInput"
        options={options ?? defaultOptions}
        onChange={handleChange}
        defaultValue={defaultValue}
        styles={customStyles}
        isClearable
        placeholder="Choose an exercise..."
      />
    </div>
  );
}

export default ExerciseSelect;
