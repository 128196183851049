import React, { useEffect, useState } from "react";
import getExercisesProgress from "../api/getExercisesProgress";
import { Contraction } from "../models";
import useAuth from "../hooks/useAuth";
import ExerciseSelect from "./ExerciseSelect";
import { SelectOption } from "./forms/SelectInput";

interface ExerciseProgress {
  contractionType: Contraction;
  exerciseId: string;
  exerciseTitle: string;
  progress: number;
}

interface Props {
  selectExercise: (exerciseId: string) => void;
}

function IsometricExerciseSelect(props: Props) {
  const { selectExercise } = props;

  const { getUserId, getAccessToken } = useAuth();
  const [completedSkills, setCompletedSkills] = useState([
    { value: "plank", label: "Plank" }
  ]);

  useEffect(() => {
    async function getProgress() {
      const result = await getExercisesProgress(getUserId(), getAccessToken());
      const exerciseProgress: ExerciseProgress[] = await result.json();

      const completedIsometricExercises = exerciseProgress
        .map(exercise => {
          if (exercise.contractionType === "isotonic") return null;

          if (exercise.progress === 0) return null;

          return {
            label: exercise.exerciseTitle,
            value: exercise.exerciseId
          };
        })
        .filter((exercise): exercise is { label: string; value: string } =>
          Boolean(exercise)
        );

      if (completedIsometricExercises.length === 0) {
        return setCompletedSkills([
          {
            label: "Plank",
            value: "plank"
          }
        ]);
      }

      return setCompletedSkills(completedIsometricExercises);
    }

    getProgress();
  }, []);

  return (
    <div>
      <label htmlFor="exercise-selector">
        <h3 style={{ textAlign: "center" }}>
          Which exercise do you want to improve your hold time for?
        </h3>
      </label>
      <div
        id="exercise-selector"
        style={{ justifyContent: "center", display: "flex" }}
      >
        <ExerciseSelect
          handleChange={value => selectExercise((value as SelectOption).value)}
          options={completedSkills}
          defaultValue={{ label: "Plank", value: "plank" }}
        />
      </div>
    </div>
  );
}

export default IsometricExerciseSelect;
